import styles from './style.module.scss'
import topicon from '../../assets/topicon.png'
import bottomIcon from '../../assets/bottomicon.png'

import bottommobile from '../../assets/desk1mobile.png'

import navMobile from '../../assets/mobileNavButton.png'
import topmobile from '../../assets/topmobile.png'
import closeButton from '../../assets/closeButton.png'

import { useEffect, useRef, useState } from 'react'
import desk6 from '../../assets/desk6.png'
import desk6mobile from '../../assets/desk6mobile.png'
import { EscrevendoEmails } from '../../components/EscrevendoEmails'
import { TermosTecnicos } from '../../components/TermosTecnicos'


export  function TermostecnicosPage() {

  const[showNavButton,setshowNavButton]= useState('none')
 
  const [showbackground,setshowbackground] = useState(bottomIcon)

  const[currentTop,setCurrentTop]= useState(topicon)
    
  const scrollRef = useRef()
  useEffect(()=>{
    if(window.innerWidth<=700){
      setshowbackground(desk6mobile)
      setCurrentTop(topmobile)
      
    }else{
      setshowbackground(desk6)
      setCurrentTop(topicon)
    }
   
  },[])
  
  document.addEventListener('click', (e) => {    
    if(e.clientX > 300){
      setshowNavButton('none')
    }
  })

  return (
    <div className={styles.homeContainer}>
       <img style={{maxWidth:'32px',marginLeft:'20px',marginTop:'40px'}} className={styles.mobileNavShow} src={navMobile} alt="" onClick={()=>setshowNavButton('block')} />
       <div className={styles.mobileButtons} style={{display:showNavButton}}>
       <div className={styles.mobilenavButtons} >
                 <img src={closeButton} alt="" onClick={()=>setshowNavButton('none')} />
                 <a style={{backgroundColor:'transparent',color:'#00223E',textDecoration:'none'}}  href="#/" >Início</a>
       
       <a style={{backgroundColor:'transparent',color:'#00223E',textDecoration:'none'}}  href="#/voztom">Voz x Tom</a>
       <a style={{backgroundColor:'transparent',color:'#00223E',textDecoration:'none'}}   href="#/atendimentovoz" >Atendimento voz</a>
       <a  style={{backgroundColor:'transparent',color:'#00223E',textDecoration:'none'}}  href="#/atendimentochat">Atendimento via chat</a>
       <a style={{backgroundColor:'transparent',color:'#00223E',textDecoration:'none'}}  href="#/erroscomuns">Erros mais comuns ao escrever</a>
       <a style={{backgroundColor:'transparent',color:'#00223E',textDecoration:'none'}}  href="#/escrevendoemails">Escrevendo e-mails</a>
       <a  style={{backgroundColor:'#142640',color:'#00B788',textDecoration:'none'}}  href="#/termostecnicos">Substituindo termos técnicos</a>

                 
             </div>
       </div>
      <img className={styles.topIcon} src={currentTop} alt="" />
        <div className={styles.mainContent}>
             <div className={styles.navButtons}>
             <a style={{backgroundColor:'transparent',color:'#00223E',textDecoration:'none'}}  href="#/" >Início</a>
       
                 <a style={{backgroundColor:'transparent',color:'#00223E',textDecoration:'none'}}  href="#/voztom">Voz x Tom</a>
                 <a style={{backgroundColor:'transparent',color:'#00223E',textDecoration:'none'}}   href="#/atendimentovoz" >Atendimento voz</a>
                 <a  style={{backgroundColor:'transparent',color:'#00223E',textDecoration:'none'}}  href="#/atendimentochat">Atendimento via chat</a>
                 <a style={{backgroundColor:'transparent',color:'#00223E',textDecoration:'none'}}  href="#/erroscomuns">Erros mais comuns ao escrever</a>
                 <a style={{backgroundColor:'transparent',color:'#00223E',textDecoration:'none'}}  href="#/escrevendoemails">Escrevendo e-mails</a>
                 <a  style={{backgroundColor:'#142640',color:'#00B788',textDecoration:'none',padding:'0px 10px'}}  href="#/termostecnicos">Substituindo termos técnicos</a>

                 
             </div>
             <div className={styles.modalContainer}>
                 
                 <TermosTecnicos/>

             </div>
        </div>
      <img className={styles.bottomIcon} src={showbackground} alt="" />

    </div>
  );
}


