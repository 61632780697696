import styles from './style.module.scss'

import blackline from '../../assets/blackline.png'
import icon from '../../assets/tecnicosIcon.png'
import btnVoltar from '../../assets/btn-voltar.png'
export function TermosTecnicos() {
    

    return (
        <div className={styles.Container}>
            <h1 style={{ color: '#021832' }}>Substituindo termos técnicos</h1>
            <div>
                <img src={blackline} alt="" style={{ maxWidth: '500px', width: '100%' }} />
            </div>
            <div>
                <p>
                    Pode ser que você tenha que explicar aos clientes alguns dos termos técnicos que usamos no dia a dia.
                    Se for fazer isso, use estes textos como guia.
                </p>
            </div>
            <div>
                <p>
                    <span style={{ fontFamily: 'verveine, sans-serif', color: '#2E85C7', fontSize: '30px' }}>Contribuição Regular</span> <br />
                    O cliente escolhe o percentual de contribuição até um limite máximo de 11%,
                    que varia de acordo com a sua faixa etária. O percentual mínimo começa em 6%
                    e é definido para cada cliente em função da sua idade, grupo familiar,
                    salário, saldo acumulado no plano e tempo de vinculação à Previdência Social.
                    Cada real que o cliente destina ao plano recebe igual contrapartida da empresa
                    patrocinadora. Isso já garante um ganho inicial automático de 100%, independentemente
                    da rentabilidade dos investimentos.


                </p>
            </div>
            <div>
                <p>
                    <span style={{ fontFamily: 'verveine, sans-serif', color: '#17B88A', fontSize: '30px' }}>
                        Contrapartida da patrocinadora</span> <br />
                    A empresa patrocinadora contribui com o mesmo percentual
                    escolhido pelo cliente para sua contribuição regular
                    para o plano, de acordo com a faixa etária. Assim,
                    há um ganho automático inicial de 100%. Nenhum outro investimento dá um retorno tão bom.

                </p>
            </div>
            <div>
                <p>
                    <span style={{ fontFamily: 'verveine, sans-serif', color: '#F5A621', fontSize: '30px' }}>
                        Contribuições Facultativas</span> <br />
                    Alguns planos como preveem contribuições facultativas.
                    Podem ser mensais, na forma de um percentual livremente
                    escolhido e descontado em folha, ou esporádicas, que podem
                    ser feitas a qualquer momento pelo cliente, para aumentar sua
                    reserva. Estas contribuições não são acompanhadas pela patrocinadora.

                </p>
            </div>
            <div style={{ display: 'flex', gap: '15px', alignItems: 'center', flexWrap: 'wrap' }}>

                <img src={icon} alt="" style={{ maxWidth: '244px', height: '330px', width: '100%' }} />

                <div>
                    <p>
                        <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '30px' }}>Renda vitalícia </span>     <br /> <br />
                        Na renda vitalícia,
                        uma vez apurado o valor inicial da renda,
                        o saldo da conta individual é transferido
                        para um fundo coletivo, responsável por garantir
                        os benefícios de todos os assistidos, conforme o
                        nome diz, de forma vitalícia. O benefício será
                        reajustado anualmente de acordo com a variação do IPCA no período.

                        <br /> <br />

                        Vale destacar que, como há formação de uma conta coletiva,
                        há mutualismo e os riscos são compartilhados, gerando solidariedade
                        entre os clientes. Portanto, nesta forma de recebimento não
                        há manutenção do patrimônio individual. Sendo assim, em caso de
                        encerramento precoce do benefício, o saldo de contas fica no
                        fundo coletivo para cobrir os benefícios daqueles que ultrapassam a expectativa de vida. <br /> <br />

                        Além disso, quando o cliente que já está aposentado morre,
                        pode deixar pensão por morte para seus beneficiários elegíveis,
                        a qual será paga de forma vitalícia. Não há saldo de conta a ser pago a herdeiros. <br /> <br />

                        <span style={{ fontFamily: 'verveine, sans-serif', color: '#6838B6', fontSize: '30px' }}>Renda por prazo indeterminado

                        </span>  <br /> <br />
                        Já na renda por prazo indeterminado, mesmo após o início do recebimento do benefício,
                        o controle de saldo conta individual do cliente é mantido, acumulando os resultados
                        dos investimentos e descontando os benefícios pagos mensalmente. A renda mensal será paga
                        enquanto houver recursos. Seu valor será recalculado em junho de cada ano da mesma forma que
                        o cálculo da renda inicial - porém com base no saldo remanescente na conta individual e
                        nas novas características do cliente e de seus beneficiários (sexo, idade etc). <br /> <br />

                        Esta forma de renda se caracteriza, principalmente,
                        por estar continuamente ajustada ao saldo de conta individual,
                        portanto, sujeita, entre outros fatores, às oscilações de rentabilidade.
                        Assim, ao longo dos anos, pode variar, para cima ou para baixo, já que,
                        anualmente, o benefício é reajustado com base no saldo de conta e em
                        premissas atuariais do plano e o perfil do cliente e seus beneficiários. <br /> <br />

                        Além disso, se o cliente que já está aposentado falecer e
                        não deixar beneficiários para pensão, o saldo da sua conta individual
                        será pago a seus herdeiros legais e/ou pessoas designadas no plano.
                    </p>

                </div>
            </div>
            <div>
                <p>
                    <span style={{ fontFamily: 'verveine, sans-serif', color: '#2E85C7', fontSize: '30px' }}>Migração
                    </span> <br />
                    É a troca de um plano para outro – sem precisar resgatar o valor do primeiro plano para aplicar
                    no novo plano. Isso evita que o cliente precise pagar imposto de renda.
                </p>
            </div>
            <div>
                <p>
                    <span style={{ fontFamily: 'verveine, sans-serif', color: '#17B88A', fontSize: '30px' }}>Tábua Atuarial</span> <br />
                    Nome dado às contas feitas para calcular a expectativa de vida das pessoas que
                    têm determinadas características ou que vivam em determinado lugar. É usada,
                    por exemplo, para calcular benefícios futuros quando alguém deseja começar
                    a investir num plano de previdência.
                </p>
            </div>
            <div>
                <p>
                    <span style={{ fontFamily: 'verveine, sans-serif', color: '#F5A621', fontSize: '30px' }}>Pecúlio por morte</span> <br />
                    É um valor em dinheiro pago de uma única vez aos beneficiários, em caso de morte do cliente. <br /><br />
                </p>
            </div>
            <a href="#/escrevendoemails/#topico-6">
                <div>
                    <img src={btnVoltar} alt="" />
                </div>
            </a>

        </div>
    )
}