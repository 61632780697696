import styles from './style.module.scss'
import passo1 from '../../assets/passo1.png'
import passo2 from '../../assets/passo2.png'
import passo3 from '../../assets/passo3.png'
import passo1icon from '../../assets/passo1icon.png'
import passo2icon from '../../assets/passo2icon.png'
import bluecheck from '../../assets/bluecheck.png'
import escutaicon from '../../assets/escutaricon.png'
import dizer from '../../assets/oqdizerIcon.png'
import naodizer from '../../assets/oqnaodizerIcon.png'
import closeicon from '../../assets/closeicon.png'


import passo3icon from '../../assets/passo3icon.png'
import digasempre from '../../assets/digasempre.png'
import fujadessas from '../../assets/fujadessas.png'
import vozline from '../../assets/vozline.png'

import lembrete from '../../assets/lembrese.png'

import frase from '../../assets/frase.png'

type AtendimentoViaProps={
    handleChange? : ()=>void
}

export function AtendimentoVia({handleChange}:AtendimentoViaProps) {

  
    return (
        <div>
            <div className={styles.Container}>
                <h1 style={{ color: '#021832' }}>Atendimento voz</h1>
                <div>
                <img src={vozline} alt="" style={{ maxWidth: '500px',width:'100%' }} />
            </div>

                <p>
                    O atendimento por telefone é, acima e antes de tudo, um contato pessoal.
                    Quem telefona para a Petros não é o cliente. É o João, a Maria, o Carlos, a Ana… <br /> <br />
                    E quem liga, sempre está em busca de uma solução. <br /> <br />
                    Por isso, a primeira dica parece óbvia, mas não é. E pode ser resumida em uma palavra: escutar. <br /> <br />
                    Deixe que a pessoa relate o problema e até que “bote para fora” alguma insatisfação que possa ter.  <br /> <br />
                    <strong>  Para facilitar sua consulta, vamos dividir este assunto em três partes: </strong>
                </p>
                <div className={styles.passosContainer} >
                    <img src={passo1} alt="" onClick={()=> document.getElementById('passo1Cont')!.scrollIntoView({behavior:'smooth'})} />
                    <img src={passo2} alt="" onClick={()=> document.getElementById('passo2Cont')!.scrollIntoView({behavior:'smooth'})} />
                    <img src={passo3} alt="" onClick={()=> document.getElementById('passo3Cont')!.scrollIntoView({behavior:'smooth'})} />
                </div>

            </div>

            <div className={styles.passo1Container} id="passo1Cont">
                <div className={styles.passo1Title}>
                    1. Técnicas de atendimento
                </div>
                <div className={styles.passo1Header}>
                    <p>São dicas de comportamento. Pense que você,
                        da Central de Relacionamento, é, muitas vezes, a
                        única pessoa da Petros com quem o cliente tem contato direto.</p>
                    <img src={passo1icon} alt="" />
                </div>

                <div className={styles.passo1Content}>
                    <p>
                        Lembre-se de que a voz impacta 5 vezes mais
                        do que as palavras na percepção de uma mensagem confiável. <br /> <br />
                        <strong>Vamos lá, então:</strong>
                    </p>
                    <div className={styles.checkContainer}>
                    <div>
                            <img src={bluecheck} alt="" style={{marginBottom:'46px'}}/>
                            <p>Para promover a aproximação com o cliente, procure usar sempre a seguinte saudação inicial “Atendimento Petros, [nome do operador], bom dia/tarde/noite! Como posso te ajudar hoje?”</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Identifique-se claramente</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Seja atencioso e mostre disposição para resolver</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Inspire confiança e demonstre que você
                                está ao lado do cliente</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" style={{ marginBottom: '38px' }} />
                            <p>Não fique cortando ou interrompendo a conversa,
                                mesmo que mentalmente já saiba, por experiência própria,
                                o que o cliente vai dizer </p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Demonstre respeito pelo cliente</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Mostre o sorriso em sua voz</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" style={{ marginBottom: '38px' }} />
                            <p>Seu tom de voz é referência no atendimento e o cliente
                                percebe se o atendente está com pressa, entediado ou desatento</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" style={{ marginBottom: '133px' }} />
                            <p > Conte até 10 – sim, é sério. Há clientes que testam a paciência.
                                Mas pense que eles não fazem isso por mal ou porque querem descontar
                                algo que pode estar se arrastando já há algum tempo. O tempo da empresa
                                é diferente do tempo do cliente. Uma solução dificilmente andará na
                                mesma velocidade que a ansiedade do cliente. E cabe ao atendimento
                                fazer essa mediação
                            </p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Se perceber que o cliente não compreendeu,
                                explique de outro jeito, usando outras palavras</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Trate o cliente pelo nome ou por Sr.(a) e evite palavras que causem
                                uma impressão de intimidade, a menos que o cliente solicite</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Ouça as solicitações atentamente, se necessário, anote as informações </p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" style={{ marginBottom: '40px' }} />
                            <p>Utilize o recurso do reconhecimento, chame-o pelo nome – com o
                                pronome de tratamento adequado: Sr.(a)</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" style={{ marginBottom: '70px' }} />
                            <p>Seja proativo com o cliente, ajude nos processos em que ele possa ter dificuldade.
                                Se precisar falar números, diga um a um e depois o número todo. <br /><strong>Exemplo:</strong><span style={{ fontFamily: 'Verdana-Itallic' }}>  4.123.
                                    Diga:  4-1-2-3, quatro mil, cento e vinte e três.</span></p>
                        </div>

                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <img src={escutaicon} alt="" />
                    </div>
                    <div className={styles.checkContainer}>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Mantenha a calma e ouça com atenção</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Faça com que o cliente perceba que você está acompanhando o que ele diz</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Não julgue. Atenda todo mundo da mesma forma</p>
                        </div>
                    </div>
                    <div className={styles.dizeresContainer}  style={{flexWrap:'wrap',justifyContent:'center'}}>
                        <img src={dizer} alt="" />
                        <img src={naodizer} alt="" />
                    </div>
                    <div>
                        <p style={{ fontSize: '14px' }}>
                            Veja algumas <strong>frases</strong> que podem <strong>quebrar o gelo e gerar conexão
                                e empatia durante o atendimento.</strong>  Lembre-se de falar do seu jeito,
                            sem que pareça “decoreba”. Você pode, inclusive, usar outras palavras,
                            mantendo o sentido.
                        </p>
                    </div>
                    <div className={styles.checkContainer} >
                        <div>
                            <img src={bluecheck} alt="" />
                            <p style={{ fontFamily: 'Verdana-Itallic' }}>Imagina! Essa dúvida é bastante comum por aqui!</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p style={{ fontFamily: 'Verdana-Itallic' }}>Eu adorei seu nome! Você sabe o significado?</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p style={{ fontFamily: 'Verdana-Itallic' }}>Como está o tempo por aí? Por aqui estamos tendo que ligar o ar-condicionado! </p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p style={{ fontFamily: 'Verdana-Itallic' }}>Como está o tempo em sua cidade hoje? Aqui esfriou bastante!</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p style={{ fontFamily: 'Verdana-Itallic' }}>Não se preocupe, sua dúvida é muito comum. Mas pode deixar que eu estou aqui pra ajudar, não é mesmo?</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p style={{ fontFamily: 'Verdana-Itallic' }}>Encerre sempre perguntando se pode ajudar em mais alguma coisa. </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className={styles.passo2Container} id="passo2Cont">
                <div className={styles.passo2Title}>
                    2. Análise da conversa
                </div>
                <div className={styles.passo2Header} style={{flexWrap:'wrap-reverse',justifyContent:'center'}}>
                    <p>Diz o ditado que é conversando que a gente se entende.
                        Vamos adaptar um pouco e dizer que é conversando que a
                        gente resolve. E, aqui vão algumas dicas retiradas de exemplos reais do dia a dia. <br /> <br />
                        Como cada um tem um modo de falar,
                        não vamos escrever frases que devam ser ditas e nem temos um roteiro – afinal,
                        todos têm o seu jeito próprio de se comunicar.</p>
                    <img src={passo2icon} alt="" />
                </div>
                <div className={styles.passo2Content}>
                    <p>
                        O que trazemos aqui são frases e atitudes que <strong>não devem ser adotadas</strong> quando estiver conduzindo uma conversa. <br /> <br />
                        <strong>Vamos lá, então:</strong>
                    </p>
                    <div className={styles.checkContainer}>
                        <div>
                            <img src={closeicon} alt="" />
                            <p>Não use termos técnicos. Para saber como explicar os planos e benefícios, <span style={{color:'#17B88A', cursor:'pointer'}} onClick={handleChange}>clique aqui.</span> </p>
                        </div>
                      
                        <div>
                            <img src={closeicon} alt="" />
                            <p>Evite perguntas em que a resposta seja SIM ou NÃO</p>
                        </div>
                        <div>
                            <img src={closeicon} alt="" />
                            <p>Se o cliente se expressa de forma confusa, pergunte com objetividade</p>
                        </div>
                        <div>
                            <img src={closeicon} alt="" />
                            <p>Jamais use o gerúndio: <span style={{ fontFamily: 'Verdana-Itallic' }}> “vou estar providenciando, vou estar fazendo”</span>. O gerúndio soa como enrolação</p>
                        </div>
                        <div>
                            <img src={closeicon} alt="" />
                            <p>Evite frases que pareçam que você quer ganhar tempo, como <span style={{ fontFamily: 'Verdana-Itallic' }}> “vou entrar no sistema”</span> ou <span style={{ fontFamily: 'Verdana-Itallic' }}> “vou entrar na sua tela”</span> ou <span style={{ fontFamily: 'Verdana-Itallic' }}> “isso é em outro setor”</span></p>
                        </div>
                        <div>
                            <img src={closeicon} alt="" />
                            <p>Assim como não é para ser muito formal, também não é para ser muito informal… então,
                                risque do caderninho termos como <span style={{ fontFamily: 'Verdana-Itallic' }}> “OK”</span >, <span style={{ fontFamily: 'Verdana-Itallic' }}> “né”</span>, <span style={{ fontFamily: 'Verdana-Itallic' }}>“tá”</span> , <span style={{ fontFamily: 'Verdana-Itallic' }}> “aí”</span>, que, na verdade,
                                são vícios de linguagem. Coisas que a gente repete sem perceber.</p>
                        </div>
                        <div>
                            <img src={closeicon} alt="" />
                            <p>Não fale rápido</p>
                        </div>
                        <div>
                            <img src={closeicon} alt="" />
                            <p>Não deixe o cliente esperando. Avise quando precisar se ausentar
                                da ligação ou do chat para analisar a situação. Informe o que está
                                acontecendo e o que está fazendo para resolver a situação</p>
                        </div>
                        <div>
                            <img src={closeicon} alt="" />
                            <p>Não fique na dúvida sobre a solicitação do cliente. Faça perguntas para confirmar seu entendimento</p>
                        </div>
                    </div>
                    <div className={styles.dizeresContainer}  style={{flexWrap:'wrap',justifyContent:'center'}}>
                        <img src={digasempre} alt="" />
                        <img src={fujadessas} alt="" />
                    </div>
                    <div style={{ margin: '0 10px', marginTop: '15px' }}>
                        <p>
                            Mas… <strong> e se você não tiver a informação?</strong> <br /> <br />
                            Neste caso, use a sinceridade, diga isso do jeito mais objetivo possível
                            e informe ao cliente que vai abrir um chamado e acionar a área responsável.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.passo3Container} id="passo3Cont">
                <div className={styles.passo3Title}>
                    3. Lidando com cliente insatisfeito
                </div>
                <div className={styles.passo3Header} style={{flexWrap:'wrap',justifyContent:'center'}}>
                    <img src={passo3icon} alt="" />
                    <p><strong> É inevitável:</strong> um dia vai ouvir (se é que já não ouviu)
                        a famosa frase <span style={{ fontFamily: 'Verdana-Itallic' }}>“quero falar com o supervisor”. </span>
                        Na maioria dos casos, o cliente não quer e essa frase
                        equivale a um pedido de ajuda. Ele provavelmente está
                        irritado com a Petros por algum motivo – repetindo: com a Petros.
                        Não com você. Não leve para o lado pessoal.</p>

                </div>
                <div className={styles.passo3Content}>
                    <p>
                        Respire fundo e aqui vamos nós:
                    </p>
                    <div className={styles.checkContainer}>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Permaneça calmo e seja objetivo</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Admita os erros cometidos pela empresa ou por você, que de fato ocorreram</p>
                        </div>                        
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Não argumente com o cliente se não tiver informações precisas</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Procure não aprovar ou reforçar os sentimentos negativos</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Deixe o cliente desabafar a raiva no começo da conversa,
                                esse é o tempo necessário para o esfriamento</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Acalme o cliente transmitindo atitude controlada, mas nunca diga <span style={{ fontFamily: 'Verdana-Itallic' }}>“fique calmo”</span> </p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Use a empatia (certamente, eu compreendo como o Sr.(a) se sente),
                                mas tenha o cuidado de não parecer que está fazendo o cliente de coitadinho</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Veja quais são os fatos e descubra o que o cliente quer que seja feito</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Diga que verificará a possibilidade de atendê-lo da forma como ele deseja, evitando criar expectativas</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Mostre interesse pelo acompanhamento do caso e comunique-se com o cliente,
                                posicionando-o das etapas da solução do seu problema. Percebendo o seu interesse,
                                o cliente permitirá que a comunicação seja mais fácil</p>
                        </div>

                    </div>

                    <div style={{justifyContent:'center',display:'flex'}}>
                        <img src={lembrete} alt="" style={{maxWidth:'450px',width:'100%'}} />
                    </div>
                    <div style={{display:'flex',flexDirection:'column',textAlign:'center',alignItems:'center',marginTop:'15px'}}>
                        <span>
                        Se, mesmo fazendo tudo isso, você ouvir a frase
                        </span> 
                        <img src={frase} alt="" style={{maxWidth:300}} />
                        <span>chegou a hora de:</span>
                    </div>
                    <div className={styles.checkContainer}>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Procure parecer seguro de si</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Diga que é a pessoa mais indicada para tratar do caso e resolver o assunto</p>
                        </div>
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Não precisa falar das funções do supervisor, dizendo frases como <span style={{fontFamily:'Verdana-Itallic'}}> “Meu supervisor não se envolve com reclamações,
                                 nem seria a pessoa certa para fazer o atendimento, porque….”</span> </p>
                        </div>
                       
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Saiba quando desistir. Faça o que puder para resolver, 
                                mas aprenda a perceber o momento em que a única maneira de acalmar o cliente é
                                transferindo o problema para o supervisor</p>
                        </div>
                       
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Antes de transferir a ligação, procure obter o maior número possível de informações.
                                 </p>
                        </div>
                       
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Diga que é a pessoa mais indicada para tratar do caso e resolver o assunto</p>
                        </div>
                       
                        <div>
                            <img src={bluecheck} alt="" />
                            <p>Em casos de ausência da supervisão, peça um telefone para contato e diga que
                                 o supervisor entrará em contato assim que possível.</p>
                        </div>
                       


                    </div>
                </div>
            </div>
        </div>
    )
}