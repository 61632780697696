import styles from './style.module.scss'
import pencil from '../../assets/pencil.png'
import balao from '../../assets/balao.png'
import escritafala from '../../assets/escritafala.png'
import vozline from '../../assets/vozline.png'
export function ErrosComuns() {
    
    return (
        <div className={styles.Container}>
            <h1 style={{ color: '#021832' }}>Erros mais comuns ao escrever</h1>
            <div>
                <img src={vozline} alt="" style={{ maxWidth: '500px',width:'100%' }} />
            </div>
            <div>
                <p>Escrever e falar errado não tem nada a ver. Ou seria haver? Pega muito mal. Ou seria mau? <br /> <br />
                    Brincadeiras à parte, a primeira dica para quem é do chat ou e-mail é:
                    use sempre o <strong> corretor ortográfico. </strong>
                    Por mais que ele possa deixar passar uma coisa ou outra, é uma ajuda e tanto
                    no dia a dia.
                    Mas a gente também selecionou <strong>dicas</strong> para você, que precisa conversar ao telefone,
                    não escorregar no português. <br /> <br />
                    Para facilitar, vamos usar ícones para sinalizar quando a dica é para uso escrito,
                    quando é para uso na fala ou quando serve para as duas situações.</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                <img src={escritafala} alt="" style={{ maxWidth: '245px' }} />
            </div>
            <div>
                <p>Agora confira algumas palavras e acentos que costumam causar confusão.</p>
            </div>
            <div className={styles.errorContainer}>

                <img src={pencil} alt="" />
                <div className={styles.errorBox}>
                    <div className={styles.errorTitle}>
                        <p>1. “Mal” e “mau”	</p>
                    </div>
                    <div className={styles.errorContent}>
                        <p style={{ textAlign: 'center', fontSize: '15px' }}>Mal com “L” e advérbio ou substantivo. Mau com “U” é adjetivo. </p>
                        <div style={{ textAlign: 'start', fontFamily: 'Verdana-Itallic' }}>
                            • Ele é chato. Está sempre de mau humor <br /> <br />
                            • No fim, o bem vence o mal <br /> <br />
                            • Me senti mal no jogo de futebol <br /> <br />
                            • Aquele jogador é mau <br /> <br />
                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.errorContainer} style={{ marginTop: '20px' }}>

                <img src={pencil} alt="" />
                <div className={styles.errorBox}>
                    <div className={styles.errorTitle}>
                        <p>2. “Aparte” e “à parte”	</p>
                    </div>
                    <div className={styles.errorContent}>
                        <p style={{ textAlign: 'center', fontSize: '15px' }}>“Aparte” pode ser o imperativo do verbo “apartar” ou substantivo masculino
                            (que significa um comentário isolado). “À parte” é aquilo que fica separado </p>
                        <div style={{ textAlign: 'start', fontFamily: 'Verdana-Itallic' }}>
                            • Eu não concedo o aparte. Vou continuar meu discurso (comentário isolado) <br /> <br />
                            • Fizemos um negócio à parte (separadamente) <br /> <br />
                            • Calcularei minha conta à parte no restaurante <br />  <br />

                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.errorContainer} style={{ marginTop: '20px' }}>
                <div className={styles.imageContainer}>
                    <img src={pencil} alt="" />
                    <img src={balao} alt="" style={{ marginRight: '-10px' }} />
                </div>
                <div className={styles.errorBox}>
                    <div className={styles.errorTitle} style={{ background: '#2E85C7' }}>
                        <p>3. “Bem” e “bom”	</p>
                    </div>
                    <div className={styles.errorContent} style={{ border: '2px solid #2E85C7' }}>
                        <p style={{ textAlign: 'center', fontSize: '15px' }}> “Mal” é o oposto de “bem”. “Mau” é o contrário de “bom”. Ou seja: vale a mesma regra </p>
                        <div style={{ textAlign: 'start', fontFamily: 'Verdana-Itallic' }}>
                            •  O bolo estava muito bom<br /> <br />
                            •  Açúcar em excesso não faz bem <br /> <br />


                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.errorContainer} style={{ marginTop: '20px' }}>
                <div className={styles.imageContainer}>
                    <img src={pencil} alt="" />
                    <img src={balao} alt="" style={{ marginRight: '-10px' }} />
                </div>
                <div className={styles.errorBox}>
                    <div className={styles.errorTitle} style={{ background: '#2E85C7' }}>
                        <p>4. “De encontro a” e “ao encontro de”	</p>
                    </div>
                    <div className={styles.errorContent} style={{ border: '2px solid #2E85C7' }}>
                        <p style={{ textAlign: 'center', fontSize: '15px' }}> “De encontro a” é algo negativo, uma oposição. “Ao encontro de” é positivo </p>
                        <div style={{ textAlign: 'start', fontFamily: 'Verdana-Itallic' }}>
                            •  As empresas que pensam na economia verde vão ao encontro das ideias que desenharão o futuro.<br /> <br />
                            •   Distraído, João foi de encontro à parede. <br /> <br />


                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.errorContainer} style={{ marginTop: '20px' }}>

                <img src={pencil} alt="" />
                <div className={styles.errorBox}>
                    <div className={styles.errorTitle}>
                        <p>5. “Há” e “a”</p>
                    </div>
                    <div className={styles.errorContent}>
                        <p style={{ textAlign: 'center', fontSize: '15px' }}>“Há” indica tempo passado. “A” informa a distância </p>
                        <div style={{ textAlign: 'start', fontFamily: 'Verdana-Itallic' }}>
                            •  O posto de gasolina fica a dois quarteirões daqui <br /> <br />
                            •  Há dois anos visitei Natal <br /> <br />


                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.errorContainer} style={{ marginTop: '20px' }}>

                <img src={pencil} alt="" />
                <div className={styles.errorBox}>
                    <div className={styles.errorTitle}>
                        <p>6. “Haver” e “a ver” </p>
                    </div>
                    <div className={styles.errorContent}>
                        <p style={{ textAlign: 'center', fontSize: '15px' }}>“Haver” é verbo. “A ver” é uma expressão que demonstra afinidade, aproximação </p>
                        <div style={{ textAlign: 'start', fontFamily: 'Verdana-Itallic' }}>
                            •  Se você fizer isso pode haver consequências <br /> <br />
                            •  Eles dois não tinham nada a ver <br /> <br />


                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.errorContainer} style={{ marginTop: '20px' }}>

                <img src={pencil} alt="" />
                <div className={styles.errorBox}>
                    <div className={styles.errorTitle}>
                        <p>7. “Haja” e “aja” </p>
                    </div>
                    <div className={styles.errorContent}>
                        <p style={{ textAlign: 'center', fontSize: '15px' }}>“Haja” e “aja” são verbos. O truque é trocar a palavra pelo verbo “existir” </p>
                        <div style={{ textAlign: 'start', fontFamily: 'Verdana-Itallic' }}>
                            •   Mesmo que haja (ou exista) algum risco, vale a pena investir nesse setor. <br /> <br />
                            •  Para que a empresa se recupere da crise, é preciso que a administração aja (do verbo agir) imediatamente <br /> <br />
                            • Ainda que haja (ou exista) uma dúvida, devemos ir em frente <br /> <br />

                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.errorContainer} style={{ marginTop: '20px' }}>

                <img src={pencil} alt="" />
                <div className={styles.errorBox}>
                    <div className={styles.errorTitle}>
                        <p>8. “A gente” e “agente” </p>
                    </div>
                    <div className={styles.errorContent}>
                        <p style={{ textAlign: 'center', fontSize: '15px' }}>Se for usado no sentido de “nós”, é separado. Se for profissão, é junto.  </p>
                        <div style={{ textAlign: 'start', fontFamily: 'Verdana-Itallic' }}>
                            •  Ela é agente de viagens <br /> <br />
                            •   Vem com a gente ver o show <br /> <br />


                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.errorContainer} style={{ marginTop: '20px' }}>

                <img src={pencil} alt="" />
                <div className={styles.errorBox}>
                    <div className={styles.errorTitle}>
                        <p>9. “Hífen” </p>
                    </div>
                    <div className={styles.errorContent}>
                        <p style={{ textAlign: 'center', fontSize: '15px' }}>
                            O hífen se aplica em vários casos. E pode ter uma explicação mais detalhada
                            ou menos detalhada. Vamos optar pela mais rápida, porque ela consegue cobrir
                            a maioria das situações de uso. Mas se você quiser se aprofundar por conta própria,
                            será excelente.  <br /> <br />
                            O truque básico muito usado em preparatórios de concursos é:  </p>
                        <div style={{ textAlign: 'start', fontFamily: 'Verdana-Itallic' }}>
                            •   os opostos se atraem <br /> <br />
                            •    os iguais se afastam<br /> <br />
                            <span style={{ fontFamily: 'Verdana, Arial, Helvetica, sans-serif' }}>Veja exemplos:</span>    <br /> <br />

                        </div>
                        <div style={{ textAlign: 'start', fontFamily: 'Verdana-Itallic', margin: '0 15px' }}>
                            •    Autoescola - <span style={{ fontFamily: 'Verdana, Arial, Helvetica, sans-serif' }}> Auto termina com “o”, que é diferente de “e”,
                                que é a primeira letra de escola. São opostos. Ou seja: eles se atraem.
                                É o mesmo caso de autoestima, infraestrutura, semiárido e outros.</span><br /> <br />
                            •    Anti-inflamatório - <span style={{ fontFamily: 'Verdana, Arial, Helvetica, sans-serif' }}> Anti termina com “i”, que também é a primeira letra de inflamatório.
                                Nesse caso, são iguais, e por isso se afastam. Assim como em micro-ondas, micro-ônibus… </span><br /> <br />

                        </div>
                        <p>
                            Existem exceções, mas estas dicas dão conta da maioria dos usos. <br /> <br />
                            <span style={{ fontFamily: 'Verdana-Bold,Arial', color: '#55B48D' }}> Quando não se deve usar o hífen?</span>  <br /> <br />
                            Quando o prefixo termina em vogal e a outra palavra começa com uma consoante diferente de “r” ou “s”.
                        </p>
                        <div style={{ textAlign: 'start', fontFamily: 'Verdana-Itallic', margin: '0 15px' }}>
                            •    Anteprojeto<br /> <br />
                            •    Geopolítica <br /> <br />
                            •    Contracheque <br /> <br />

                        </div>
                        <p>
                        Quando o prefixo termina em consoante e a outra palavra começa 
                        com vogal ou uma consoante diferente. <br /> <br />
                        </p>
                        <div style={{ textAlign: 'start', fontFamily: 'Verdana-Itallic', margin: '0 15px' }}>
                            •    Hiperativo<br /> <br />
                            •     Subemprego <br /> <br />
                            •    Superpopulação <br /> <br />
                            •    Superpromoção <br /> <br />

                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.errorContainer} style={{ marginTop: '20px' }}>
                <div className={styles.imageContainer}>
                    <img src={pencil} alt="" />
                    <img src={balao} alt="" style={{ marginRight: '-10px' }} />
                </div>
                <div className={styles.errorBox}>
                    <div className={styles.errorTitle} style={{ background: '#2E85C7' }}>
                        <p>10. “Para mim” ou “para eu” 	</p>
                    </div>
                    <div className={styles.errorContent} style={{ border: '2px solid #2E85C7' }}>
                        <p style={{ textAlign: 'center', fontSize: '15px' }}>
                        Ambos podem estar 100% corretos. Mas sempre vai depender do uso. Se você vai continuar a frase com um verbo, use “para eu”. </p>
                        <div style={{ textAlign: 'start', fontFamily: 'Verdana-Itallic' }}>
                            •  Ela disse que vai trazer os livros para mim<br /> <br />
                            •   Ela disse que vai trazer os livros para eu ler <br /> <br />


                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}