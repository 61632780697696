import styles from './style.module.scss'
import vozicon from '../../assets/vozicon.png'
import tomicon from '../../assets/tomicon.png'
import tableicon from '../../assets/tableicon.png'
import vozline from '../../assets/vozline.png'
import voztommobile from '../../assets/voztommobile.png'
import { useState } from 'react'
export function Vozmodal(){

  const[currentImage,setCurrentImage] = useState(tableicon)
  const[currentWidth,setcurrentWidth] = useState('425px')
  function changeImage(){
         if(window.innerWidth<=730){
          setCurrentImage(voztommobile)
          setcurrentWidth('256px')
         }else{
          setCurrentImage(tableicon)
          setcurrentWidth('425px')
         }
  }

  window.addEventListener('resize',changeImage)
    return(
        <div className={styles.Container}>
           <h1 style={{color:'#142640'}}>Voz X Tom</h1>
           <div>
                <img src={vozline} alt="" style={{ maxWidth: '500px',width:'100%' }} />
            </div>
            <div style={{textAlign:'center',marginTop:'20px'}}>
              <img src={vozicon} alt=""  style={{maxWidth:'200px'}}/>
            </div>
            <p>
            É a missão da empresa traduzida em palavras. <br /> <br />
            A missão da Petros é pagar benefícios aos nossos participantes de forma eficiente,
             transparente e responsável, com base numa gestão de excelência. <br /> <br />
            Note que usamos três adjetivos:  <strong> eficiente</strong>, <strong> transparente</strong> e <strong>responsável.</strong> 
            </p>
            <div style={{textAlign:'center',marginTop:'20px'}}>
              <img src={tomicon} alt=""  style={{maxWidth:'200px'}}/>
            </div>
            <p>
         <strong>Exemplificando</strong>: é como se a voz fosse a língua que você fala. E o tom
             fosse o sotaque com que você fala. <br /> <br />
        
            Na Petros, queremos que o <strong>tom</strong>  seja <strong>respeitoso</strong>, <strong>leve</strong>  e <strong>amigável.</strong> 
            </p>
            <div style={{textAlign:'center',marginTop:'20px'}}>
     
                 <img src={currentImage} alt=""  style={{maxWidth:currentWidth,width:'100%'}}/>:
               
                 
          
            </div>
        </div>
    )
}