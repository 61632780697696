
import { BrowserRouter,Routes,Route,HashRouter } from 'react-router-dom'
import { AtendimentochatPage } from './pages/AtendimentochatPage'
import { AtendimentovozPage } from './pages/AtendimentovozPage'
import { ErroscomunsPage } from './pages/ErroscomunsPage'
import { EscrevendoemailPage } from './pages/EscrevendoemailPage'
import  {Home}  from './pages/HomePage'
import { TermostecnicosPage } from './pages/TermostecnicosPage'
import { VoztomPage } from './pages/VozTomPage'

export default function App() {

            return(
          
            
                 <Routes>
                  <Route path='/'  element={<Home/>}  />
                  <Route path='/voztom' element={<VoztomPage/>}  />
                  <Route path='/atendimentovoz' element={<AtendimentovozPage/>}  />
                  <Route path='/atendimentochat' element={<AtendimentochatPage/>}  />
                  <Route path='/erroscomuns' element={<ErroscomunsPage/>}  />
                  <Route path='/escrevendoemails' element={<EscrevendoemailPage/>}  />
                  <Route path='/termostecnicos' element={<TermostecnicosPage/>}  />

                 </Routes>
              
            )

}


