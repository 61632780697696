import styles from './style.module.scss'
import redline from '../../assets/redline.png'

import one from '../../assets/oneIcon.png'
import two from '../../assets/twoIcon.png'
import three from '../../assets/threeIcon.png'
import four from '../../assets/fourIcon.png'
import five from '../../assets/fiveIcon.png'
import six from '../../assets/sixIcon.png'
import seven from '../../assets/sevenIcon.png'
import eight from '../../assets/eightIcon.png'
import nine from '../../assets/nineIcon.png'
import ten from '../../assets/tenIcon.png'
import eleven from '../../assets/elevenIcon.png'
import twelve from '../../assets/twelveIcon.png'
import thirteen from '../../assets/thirteenIcon.png'
import fourteenIcon from '../../assets/fourteenIcon.png'
import fiveteenIcon from '../../assets/fiveteenIcon.png'

import use from '../../assets/use.png'
import emvezde from '../../assets/emvezde2.png'
import tutorial from '../../assets/tutorial.png'

import email1 from '../../assets/email1.png'
import email2 from '../../assets/email2.png'
import email3 from '../../assets/email3.png'

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function EscrevendoEmails() {


    const location = useLocation();

    useEffect(() => {
        
        // Obtém o elemento DOM com o ID especificado
        const element = document.getElementById(location.hash.replace('#', ''));
       
        // Dá scroll para o elemento
        if (element) {
            element.scrollIntoView();
            window.scrollTo(0, 0);
        }
    }, [location]);


    return (
        <div>


            <div className={styles.Container}>
                <h1 style={{ color: '#021832' }}>Escrevendo e-mails</h1>
                <div>
                    <img src={redline} alt="" style={{ maxWidth: '500px', width: '100%' }} />
                </div>
                <div>
                    <p>Em geral, a linguagem que adotamos pode ser caracterizada como formal coloquial. <br /> <br />
                        Claro que, ao escrevermos uma mensagem utilizando o “Sr(a)”, essa linguagem passa por adaptações. <br /> <br />
                        Nosso grande objetivo é escrever de forma <strong>direta</strong>, <strong>simples</strong> e <strong>eficaz</strong>. Quanto mais compreendido for o texto, menos
                        dúvidas serão geradas e menos atendimentos precisarão ser feitos. <br /> <br />
                        A escrita de cada pessoa tem um estilo próprio e aqui pretendemos estabelecer um
                        padrão que deve ser usado por todos. Por isso, serão apenas regras gerais,
                        mas que deverão ser seguidas.</p>
                </div>
                <div className={styles.pointsContainer}>
                    <div className={styles.pointsBox}>
                        <div>
                            <img src={one} alt="" style={{ marginTop: '13px' }} />
                        </div>
                        <p> <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px' }}> Em e-mails nunca usamos a primeira pessoa.</span> <br />
                            Sempre utilizamos “nós”. Em alguns casos, para evitar repetições,
                            pode-se usar o “a gente”</p>
                    </div>
                    <div className={styles.pointsBox}>
                        <div>
                            <img src={two} alt="" style={{ marginTop: '13px' }} />
                        </div>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px', lineHeight: '28px' }}>
                                Busque a forma mais simples de escrever uma mensagem.
                            </span> <br />
                        </p>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <img src={use} alt="" style={{ maxWidth: '250px', width: '100%' }} />
                        <img src={emvezde} alt="" style={{ maxWidth: '250px', width: '100%' }} />
                    </div>
                    <div className={styles.pointsBox}>
                        <div>
                            <img src={three} alt="" style={{ marginTop: '13px' }} />
                        </div>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px', lineHeight: '28px' }}>
                                Não use CAIXA ALTA, porque as pessoas leem como se fosse um grito.
                            </span> <br />
                        </p>
                    </div>
                    <div className={styles.pointsBox}>
                        <div>
                            <img src={four} alt="" style={{ marginTop: '13px' }} />
                        </div>
                        <p> <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px' }}> Evite usar a interrogação.</span> <br />
                            Nós não temos que criar dúvidas. Nós, como relacionamento com o cliente,
                            temos que gerar certezas. Isso não se aplica a campanhas publicitárias,
                            que podem usar a interrogação como recurso para instigar o cliente.</p>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#142640', fontSize: '28px' }}>
                                Faça o seu aporte. O tempo está acabando.
                            </span> <br />
                            (texto não publicitário)
                        </p>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#142640', fontSize: '28px' }}>
                                Já fez o seu aporte? O tempo está acabando.
                            </span> <br />
                            (texto publicitário)
                        </p>
                    </div>
                    <div className={styles.pointsBox}>
                        <div>
                            <img src={five} alt="" style={{ marginTop: '13px' }} />
                        </div>
                        <p> <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px' }}> Também evite exclamações.</span> <br />
                            Uma comunicação sóbria não precisa dessas formas de expressões.
                            A mesma exceção se aplica às peças publicitárias e comemorativas.</p>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#142640', fontSize: '28px' }}>
                                Parabéns! Você está completando mais um ano na Petros.
                            </span>

                        </p>
                    </div>
                    <div id="topico-6" className={styles.pointsBox}>
                        <div>
                            <img src={six} alt="" style={{ marginTop: '6px' }} />
                        </div>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px', lineHeight: '28px' }}>
                                Cuidado com o uso de hifens (-) e travessões ( – ).
                            </span> <br />
                        </p>
                    </div>
                    <div  className={styles.pointsBox}>
                        <div>
                            <img src={seven} alt="" style={{ marginTop: '13px' }} />
                        </div>
                        <p> <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px' }}> Não use termos técnicos.</span> <br />
                            Veja como substituí-los <a style={{ color: '#DE456B' }} href="#/termostecnicos" >clicando aqui.</a> </p>
                    </div>
                    <div className={styles.pointsBox}>
                        <div>
                            <img src={eight} alt="" style={{ marginTop: '13px' }} />
                        </div>
                        <p> <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px', lineHeight: '28px' }}>
                            Se for usar siglas, coloque o que significa a sigla e
                            depois a coloque entre parentêses ou depois de travessão.</span> <br />

                            Isso só precisa ser feito na primeira vez em que a sigla aparece no texto.</p>
                    </div>
                    <div className={styles.pointsBox}>
                        <div>
                            <img src={nine} alt="" style={{ marginTop: '13px' }} />
                        </div>
                        <p> <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px' }}>
                            Use a voz ativa,</span> <br />
                            Onde o sujeito é o agente da ação e não a voz passiva, em que o sujeito sofre a ação. </p>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#142640', fontSize: '28px' }}>
                                A Petros irá resolver esta questão
                            </span> <br />
                            (Voz ativa)
                        </p>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#142640', fontSize: '28px' }}>
                                Esta questão será resolvida pela Petros
                            </span> <br />
                            (Voz passiva)
                        </p>
                    </div>
                    <div className={styles.pointsBox}>
                        <div>
                            <img src={ten} alt="" style={{ marginTop: '6px' }} />
                        </div>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px', lineHeight: '28px' }}>
                                Caso seja necessário dar alguma notícia que desagrade ao cliente,
                                tente reverter a negatividade.
                            </span> <br />
                        </p>
                    </div>
                    <div style={{ textAlign: 'center', margin: '0 35px' }}>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#142640', fontSize: '28px', lineHeight: '28px' }}>
                                A documentação que o(a) Sr.(a) enviou não está completa e, por isso, sua solicitação está parada
                            </span> <br />
                            (Frase negativa)
                        </p>
                    </div>
                    <div style={{ textAlign: 'center', margin: '0 35px' }}>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#142640', fontSize: '28px', lineHeight: '28px' }}>
                                Ainda faltam documentos para que sua solicitação siga em frente. Por favor, providencie o envio
                            </span> <br />
                            (Frase positiva/reversa)
                        </p>
                    </div>
                    <div style={{ textAlign: 'center', margin: '0 35px' }}>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#142640', fontSize: '28px', lineHeight: '28px' }}>
                                Precisamos que o(a) Sr.(a) envie o documento XXXXXX para darmos sequência à sua solicitação.
                            </span> <br />
                            (Frase positiva/reversa)
                        </p>
                    </div>

                    <div className={styles.pointsBox}>
                        <div>
                            <img src={eleven} alt="" style={{ marginTop: '13px' }} />
                        </div>
                        <p> <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px', lineHeight: '28px' }}>
                            Ao orientar sobre o uso de aplicativo ou site, explique o caminho por extenso e
                            em tópicos e coloque em negrito o que for mais importante.</span> <br />
                            - Acesse <strong>portalpetros.com.br </strong> <br />
                            - No canto superior direito, digite sua matrícula Petros e sua <strong>senha</strong>  <br />
                            - Clique em <strong>Entrar</strong><br />
                            - Para colocar em negrito, selecione o trecho ou palavra que deseja destacar e aperte as teclas<strong> Ctrl+B</strong><br />
                            - Para sublinhar, selecione o trecho ou palavra que deseja destacar e aperte as teclas<strong> Ctrl+U </strong></p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={tutorial} alt="" style={{ maxWidth: '475px', width: '100%' }} />
                    </div>
                    <div className={styles.pointsBox}>
                        <div>
                            <img src={twelve} alt="" style={{ marginTop: '13px' }} />
                        </div>
                        <p> <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px' }}>
                            Releia o que escreveu e veja se está claro.</span> <br />
                            Se coloque no lugar do cliente: se eu recebesse essa mensagem eu entenderia o que escrevi? </p>
                    </div>
                    <div className={styles.pointsBox}>
                        <div>
                            <img src={thirteen} alt="" style={{ marginTop: '6px' }} />
                        </div>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px', lineHeight: '28px' }}>
                                Para escrever mensagens em chats ou aplicativos de mensagens, veja as dicas <a style={{ color: '#DE456B' }} href="#/atendimentochat">aqui.</a>
                            </span> <br />
                        </p>
                    </div>
                    <div className={styles.pointsBox}>
                        <div>
                            <img src={fourteenIcon} alt="" style={{ marginTop: '6px' }} />
                        </div>
                        <p>
                            <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px', lineHeight: '28px' }}>
                                Se precisar utilizar algum termo técnico, <a style={{ color: '#DE456B' }} href="#/termostecnicos"> clique aqui.</a>
                            </span> <br />
                        </p>
                    </div>
                    <div className={styles.pointsBox}>
                        <div>
                            <img src={fiveteenIcon} alt="" style={{ marginTop: '13px' }} />
                        </div>
                        <p> <span style={{ fontFamily: 'verveine, sans-serif', color: '#DE456B', fontSize: '28px', lineHeight: '28px' }}>
                            Preparamos ainda uma lista com os erros mais
                            comuns que as pessoas cometem ao escrever.
                            E ela está <a style={{ color: '#DE456B' }} href="#/erroscomuns"> aqui.</a>
                            .</span> <br />
                            Confira agora alguns exemplos de e-mails que estamos enviando atualmente, seguindo as recomendações que você leu. </p>
                    </div>

                </div>

            </div>

            <div className={styles.banner1Container}>
                <div>
                    <img src={email1} alt="" />
                </div>
                <div>
                    <img src={email2} alt="" />
                </div>
                <div id='email3'>
                    <img src={email3} alt="" />
                </div>
            </div>
        </div>
    )

}