import styles from './style.module.scss'
import topicon from '../../assets/topicon.png'
import bottomIcon from '../../assets/bottomicon.png'

import bottommobile from '../../assets/desk1mobile.png'

import navMobile from '../../assets/mobileNavButton.png'
import topmobile from '../../assets/topmobile.png'
import closeButton from '../../assets/closeButton.png'
import desk2 from '../../assets/desk2.png'
import desk2mobile from '../../assets/desk2mobile.png'
import { useEffect, useRef, useState } from 'react'
import { BaseModal } from '../../components/BaseModal'
import { Vozmodal } from '../../components/VozTomModal'
import { AtendimentoVia } from '../../components/AtendimentoVia'


export function AtendimentovozPage() {

  const [showNavButton, setshowNavButton] = useState('none')

  const [showbackground, setshowbackground] = useState(bottomIcon)

  const [currentTop, setCurrentTop] = useState(topicon)

  const scrollRef = useRef()
  useEffect(() => {
    if (window.innerWidth <= 700) {
      setshowbackground(desk2mobile)
      setCurrentTop(topmobile)

    } else {
      setshowbackground(desk2)
      setCurrentTop(topicon)
    }

  }, [])

  document.addEventListener('click', (e) => {
    if (e.clientX > 300) {
      setshowNavButton('none')
    }
  })

  return (
    <div className={styles.homeContainer}>
      <img style={{ maxWidth: '32px', marginLeft: '20px', marginTop: '40px' }} className={styles.mobileNavShow} src={navMobile} alt="" onClick={() => setshowNavButton('block')} />
      <div className={styles.mobileButtons} style={{ display: showNavButton }}>
        <div className={styles.mobilenavButtons} >
          <img src={closeButton} alt="" onClick={() => setshowNavButton('none')} />
          <a style={{ backgroundColor: 'transparent', color: '#00223E', textDecoration: 'none' }} href="#/" >Início</a>

          <a style={{ backgroundColor: 'transparent', color: '#00223E', textDecoration: 'none' }} href="#/voztom">Voz x Tom</a>
          <a style={{ backgroundColor: '#142640', color: '#00B788', textDecoration: 'none' }} href="#/atendimentovoz" >Atendimento voz</a>
          <a style={{ backgroundColor: 'transparent', color: '#00223E', textDecoration: 'none' }} href="#/atendimentochat">Atendimento via chat</a>
          <a style={{ backgroundColor: 'transparent', color: '#00223E', textDecoration: 'none' }} href="#/erroscomuns">Erros mais comuns ao escrever</a>
          <a style={{ backgroundColor: 'transparent', color: '#00223E', textDecoration: 'none' }} href="#/escrevendoemails">Escrevendo e-mails</a>
          <a style={{ backgroundColor: 'transparent', color: '#00223E', textDecoration: 'none' }} href="#/termostecnicos">Substituindo termos técnicos</a>


        </div>
      </div>
      <img className={styles.topIcon} src={currentTop} alt="" />
      <div className={styles.mainContent}>
        <div className={styles.navButtons}>
          <a style={{ backgroundColor: 'transparent', color: '#00223E', textDecoration: 'none' }} href="#/">Início</a>

          <a style={{ backgroundColor: 'transparent', color: '#00223E', textDecoration: 'none' }} href="#/voztom">Voz x Tom</a>
          <a style={{ backgroundColor: '#142640', color: '#00B788', textDecoration: 'none', padding: '0px 10px' }} href="#/atendimentovoz" >Atendimento voz</a>
          <a style={{ backgroundColor: 'transparent', color: '#00223E', textDecoration: 'none' }} href="#/atendimentochat">Atendimento via chat</a>
          <a style={{ backgroundColor: 'transparent', color: '#00223E', textDecoration: 'none' }} href="#/erroscomuns">Erros mais comuns ao escrever</a>
          <a style={{ backgroundColor: 'transparent', color: '#00223E', textDecoration: 'none' }} href="#/escrevendoemails">Escrevendo e-mails</a>
          <a style={{ backgroundColor: 'transparent', color: '#00223E', textDecoration: 'none' }} href="#/termostecnicos">Substituindo termos técnicos</a>


        </div>
        <div className={styles.modalContainer}>

          <AtendimentoVia />

        </div>
      </div>
      <img className={styles.bottomIcon} src={showbackground} alt="" />

    </div>
  );
}


