import styles from './styles.module.scss'
import balao from '../../assets/baloon.png'
import baseline from '../../assets/baseline.png'
import { useEffect } from 'react'



export function BaseModal(){
   
    return(
        <div className={styles.Container}>
           <h1 id={'baseTitle'} style={{color:'#142640'}}>Olá! Este é o Guia de Estilo 
            e de Linguagem Petros</h1>
            <div>
                <img src={baseline} alt="" style={{ maxWidth: '500px',width:'100%' }} />
            </div>
            <p>
            O movimento <strong> A GENTE RESOLVE </strong>quer desenvolver na Petros a cultura do encantamento.
             Transformar a experiência do cliente ao se relacionar com a empresa. Isso vai iniciar 
             pelos canais de atendimento, que começarão a usar uma linguagem mais simples,
              mais objetiva e, principalmente, mais próxima daquela usada na vida real. <br /> <br />

                Este Guia foi criado para <strong> ajudar você a entender e aplicar, no seu dia a dia,
                 esta nova forma de se comunicar.</strong> Separamos dicas e orientações práticas para uso geral. <br /> <br />

                Para consultar as informações e tirar dúvidas, <strong> basta navegar o menu aí ao
                 lado</strong> e selecionar o assunto que deseja.
            </p>
            <div className={styles.baloonContainer}>
                <img src={balao} alt="" />
                <div className={styles.baloonDescription}>
                    <p>
                    Este Guia não pretende robotizar ou engessar seu jeito de falar e de se comunicar.
                     O objetivo é unificar os caminhos para você fazer isso da melhor forma possível, 
                     dentro da busca pela cultura do encantamento Petros.
                    </p>
                </div>
                <div>
                    <p>O conteúdo disponibilizado pode ser utilizado para atendimentos de voz no 0800 e na comunicação por escrito, em e-mails e chats.</p>
            
                </div>
            </div>
        </div>
    )
}