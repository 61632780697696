import styles from './style.module.scss'


import simnao from '../../assets/simnao.png'
import emvezde from '../../assets/emvezde.png'
import diga from '../../assets/diga.png'
import linepurple from '../../assets/linepurple.png'
import chatmobile from '../../assets/chatemailmobile.png'
import { useState } from 'react'
export function AtendimentoViaChat(){
    
    const[currentImage,setCurrentImage] = useState(simnao)
    const[currentWidth,setcurrentWidth] = useState('500px')
    function changeImage(){
           if(window.innerWidth<=630){
            setCurrentImage(chatmobile)
            setcurrentWidth('390px')
           }else{
            setCurrentImage(simnao)
            setcurrentWidth('500px')
           }
    }
  
    window.addEventListener('resize',changeImage)
    return(
        <div className={styles.Container}>
           <h1 style={{ color: '#021832' }}>Atendimento via Chat e E-mail</h1>
           <div>
                <img src={linepurple} alt="" style={{ maxWidth: '500px',width:'100%' }} />
            </div>

           <p>
                Mesmo no atendimento via chat ou por e-mail,
                    a voz e o tom da Petros precisam estar presentes.
                    Veja o que você pode ou não utilizar nos seus atendimentos.
                </p>
                <div style={{display:'flex',justifyContent:'center'}}>
                    <img src={currentImage} alt="" style={{maxWidth:currentWidth,width:'100%'}} />
                </div>
                <div style={{textAlign:'center',fontFamily:'verveine, sans-serif',fontSize:'28px',color:'#142640',lineHeight:'28px'}}>
                    <p>Atendimento Petros, [nome do operador], bom dia/tarde/noite! Como posso te ajudar hoje?</p>
                </div>
                <div>
                    <p>
                    Exemplo de reversão de uso de palavras negativas:
                    </p>
                </div>
                <div className={styles.modalFooter} style={{flexWrap:'wrap'}}>
                        <div>
                            <img src={emvezde} alt="" />
                      
                        </div>
                        <div>
                            <img src={diga}alt="" />
                      
                        </div>
                </div>
        </div>
    )
}